<template>
  <div>
    <div
      class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 pt-1"
    >
      <div
        v-for="(item, index) in data.buisness_builder_products"
        :key="item.objectID"
        class="w-full hover:shadow-lg rounded pb-5"
      >
        <item-list-view-2
          :item="item"
          :inCart="isInCartTest(item._id) && !isProductScript(item) && !isProductBundle(item)"
          :index="index"
          :superAdminCanAddToCart="superAdminCanAddToCart"
          @quantityChange="quantityChange"
        >
          <template slot="action-buttons">
            <div class="w-full px-4" v-if="!isInCartTest(item._id) || isProductScript(item) || isProductBundle(item)">
              <div
                @click="cartButtonClicked(item)"
                v-if="(item.canBuy || superAdminCanAddToCart) && item.isAvailable"
                class="item-view-secondary-action-btn w-full py-2 rounded-lg items-center justify-center text-white cursor-pointer text-center"
                style="background-color: #0E4333"
              >
                <span class="text-base font-base">Add to Cart</span>
              </div>
            </div>

            <div v-if="!item.canBuy && !superAdminCanAddToCart" class="w-full px-4">
              <div
                v-if="item.supplier.supplierOnboardingLink"
                class="item-view-secondary-action-btn w-full py-2 rounded-lg items-center justify-center text-white cursor-pointer text-center"
                style="background-color: #0E4333"
                @click="learMore(item.supplier.supplierOnboardingLink)"
              >
                <span class="text-base font-base">Learn More</span>
              </div>
              <div
                class="item-view-secondary-action-btn w-full py-2 rounded-lg items-center justify-center text-white cursor-pointer text-center"
                style="background-color: #0E4333"
                @click="popupActive = true"
                v-else
              >
                <span class="text-base font-base">Contact Fresh</span>
              </div>
            </div>

            <div v-if="!item.isAvailable && item.canBuy" class="w-full px-4">
              <div
                class="item-view-secondary-action-btn bg-gray-400 w-full py-2 rounded-lg items-center justify-center text-white cursor-pointer text-center"
              >
                <span class="text-sm font-base">Out of Stock</span>
              </div>
            </div>
          </template>
        </item-list-view-2>
      </div>
    </div>
    <vs-popup
      class="holamundo"
      title="Supplier Account Required"
      :active.sync="popupActive"
    >
      <div class="flex flex-no-wrap">
        <div class="w-1/6 flex items-center justify-center">
          <vs-icon
            icon="clear"
            size="medium"
            bg="#f9d6d6"
            color="#b21f1e"
            round
          ></vs-icon>
        </div>
        <div class="w-5/6">
          <ContactFresh :email="contactEmail" :number="contactNumber" />
        </div>
      </div>
      <div class="flex justify-end mt-5">
        <vs-button class="mr-4 mb-4" @click="popupActive = false">
          I understand
        </vs-button>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import ContactFresh from "../../../components/ContactFresh.vue";

export default {
  inject: ["getRoleRouteName"],

  components: {
    ItemListView2: () =>
      import("@/views/org-owner/ecommerce/ItemListView2.vue"),
    ContactFresh,
  },
  props: ['product_ids', 'isPreviewPage'],
  data() {
    return {
      data: {
        buisness_builder_products: [],
      },
      canAdd: false,
      clinicId: "",
      popupActive: false,
      contactEmail: this.contactFresh("email"),
      contactNumber: this.contactFresh("number"),
    };
  },
  computed: {
    isInCartTest() {
      return (itemId) => this.$store.getters["eCommerce/isInCartTest"](itemId);
    },
    isLoggedInSuperAdmin() {
      return this.$store.state.AppActiveUser.userType === "superAdmin"
    },
    superAdminCanAddToCart() {
      return this.isLoggedInSuperAdmin && !!this.canAdd
    }
  },
  methods: {
    ...mapActions("ecommerce", ["getHomepageProductsByIds"]),
    ...mapActions("ecommerce", ["getCartItemsFromApi"]),
    ...mapActions("admin", ["getSettingsByKey"]),

    learMore(url){
      window.open(url);
    },
    isProductScript(item) {
      return item && item.type === "script";
    },
    isProductBundle(item) {
      return item && item.type === "bundle";
    },
    quantityChange(data) {
      if (this.data.buisness_builder_products[data.index].type !== "basic") {
        this.$router
          .push({
            name: this.getRoleRouteName("ProductDetail"),
            params: {
              id: this.data.buisness_builder_products[data.index]._id,
            },
          })
          .catch(() => {});
        return false;
      }
      this.data.buisness_builder_products[data.index].selectedQuantity =
        data.quantity;
    },
    async cartButtonClicked(item) {
      if (item.tcc || item.type === "bundle" || item.type === "lead") {
        this.$router
          .push({
            name: this.getRoleRouteName("ProductDetail"),
            params: {
              id: item._id,
            },
          })
          .catch(() => {});
        return false;
      }

      let isValid = await this.$validator.validateAll("basic");
      if (!isValid) {
        this.$vs.notify({
          title: "Error",
          text: "Validation Failed",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
        });
        return false;
      }
      if (item.canAdd === false) {
        this.$vs.notify({
          title: "Failed",
          text:
            "Selected quantity must not be less than " +
            item.inventory.requiredQuantity,
          color: "danger",
        });
        return false;
      }
      const cartQuantity = this.totalItemQuantityIncart(item._id);
      if (item.selectedQuantity === 0) {
        this.$vs.notify({
          title: "Failed",
          text: "Quantity cannot be zero",
          color: "danger",
        });
        return false;
      }
      if (item.isAvailable !== true) {
        this.$vs.notify({
          title: "Failed",
          text: "Product is not available right now.",
          color: "danger",
        });
        return false;
      }

      const totalCartAndSelectedQty = cartQuantity + item.selectedQuantity;
      if (
        item.inventory.maxQuantity !== null &&
        item.inventory.maxQuantity !== 0
      ) {
        if (
          item.selectedQuantity > item.inventory.maxQuantity ||
          totalCartAndSelectedQty > item.inventory.maxQuantity
        ) {
          this.$vs.notify({
            title: "Failed",
            text: "You've exceeded maximum order amount.",
            color: "danger",
          });
          return false;
        }
      }
      /* if(item.selectedQuantity < item.quantity){
           this.$vs.notify({
             title: "Failed",
             text: 'Selected quantity is greater than available quantity',
             color: "danger",
           });
           return false;
         }*/
      // if((cartQuantity + item.selectedQuantity) > item.quantity){
      //   this.$vs.notify({
      //     title: "Failed",
      //     text: 'Your cart exceeds the quantity of this product.',
      //     color: "danger",
      //   });
      //   return false;
      // }

      this.additemInCart(item, "increment");
      // console.log(this.isInCart(item._id));
      // this.isInCart(item._id) ? this.$router.push('/apps/eCommerce/checkout').catch(() => {}) : this.additemInCart(item)
    },
    totalItemQuantityIncart() {
      return (itemId) =>
        this.$store.getters["eCommerce/totalItemQuantityIncart"](itemId);
    },
    additemInCart(data, type) {
      let minQty = 1;
      if (data.inventory.requiredQuantity > 1) {
        minQty = data.inventory.requiredQuantity;
      }
      let item = { data: data, type: type, minQty: minQty };

      this.$vs.loading();

      item.data.shipping.shippingFee = item.data.supplier.deliveryDetails
        ? item.data.supplier.deliveryDetails.deliveryFee
        : 0;

      this.$store
        .dispatch("eCommerce/additemInCartHomepage", item)
        .then((response) => {
          this.getCartItemsFromApi(), this.$vs.loading.close();
          this.$vs.notify({
            color: "success",
            title: "Product added",
            text: "Product was successfully added to your cart.",
          });
        })
        .catch((err) => {
          console.log("err", err);
          this.$vs.loading.close();
          const supplierErrMsg = "Sorry! You need to setup a supplier account to purchase this product."
          if (err.data && err.data.message && err.data.message === supplierErrMsg) {
            this.data.buisness_builder_products.find(p => p._id.toString() === data._id.toString()).canBuy = false;
            this.$vs.notify({
              color: 'danger',
              title: 'Add to cart failed',
              text: supplierErrMsg,
              time: 5000,
            });
            return;
          }
          this.$vs.notify({
            color: "danger",
            title: "Failed",
            text: "Failed to add product to cart. Please try again later.",
          });
        });
    },
    getProducts() {
      this.data.buisness_builder_products = []
      this.$vs.loading()
      const ids = this.product_ids

      this.getHomepageProductsByIds({ids:this.product_ids, clinicId: this.clinicId, isPreviewPage: this.isPreviewPage})
        .then(({ data }) => {

          const idIndexMap = new Map();
          
          ids.forEach((id, index) => {
              idIndexMap.set(id, index);
          });
          
          this.data.buisness_builder_products = data.data.map((val)=>val)
          this.data.buisness_builder_products.sort((a, b) => {
              const indexA = idIndexMap.get(a._id);
              const indexB = idIndexMap.get(b._id);
              return indexA - indexB;
          });

          this.$vs.loading.close();

        })
        .catch((err) => {
          console.log(err);
          this.$vs.loading.close();
        });
    },
    contactFresh(contact) {
      if (process.env.VUE_APP_REGION !== "AU") {
        if (contact === "email")
          return (this.contactEmail = "hello@freshclinics.com");
        if (contact === "number")
          return (this.contactNumber = "+1 888-925-1462");
      }

      if (contact === "email")
        return (this.contactEmail = "info@freshclinics.com.au");
      if (contact === "number") return (this.contactNumber = "1300 375 646");
    },
    async fetchCanAddSettingKey() {
      try {
        const { data } = await this.getSettingsByKey("superAdminCanAdd")

        if (data.data) this.canAdd = data.data.value === "yes"
      } catch (err) {
        console.log(' ----> error fetching can-add setting key ')
        console.log(err)
      }
    }
  },
  created() {
    this.clinicId = sessionStorage.getItem(`doctorClinicSelectedClinic`);

    this.getProducts();

    if (this.isLoggedInSuperAdmin) this.fetchCanAddSettingKey()
  },
  watch: {
    "$store.state.clinicId": function (val) {
      this.clinicId = val;

      this.getProducts();
    },
  },
};
</script>

<style lang="scss" scoped>
.items-wrapper {
  > div {
    border: 1px solid #eaeaea;
  }
}
</style>
